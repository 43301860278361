<template>
  <div>
    <TemplatesOverviewBar
      v-model="templateSearch"
      :company="company"
      :group="group"
      :is-my-tab="isMyTab"
      :is-company-tab="isCompanyTab"
      :is-group-tab="isGroupTab"
      :user-role="userRole"
      :is-single-acc="isSingleAcc"
      :is-lavvira-tab="isLavviraTab"
      :actual-tab="actualTab"
      @selection="viewIsSelected"
    />
    <v-container
      v-if="myTemplates && myTemplates.length"
      fluid
      :style="`height: ${vuetifyHeight - 200 + 'px'};`"
    >
      <TemplatesTable
        v-if="selection === 'table'"
        :filtered-templates-by-category="filteredTemplatesByCategory"
        :company="company"
        :group="group"
        :is-my-tab="isMyTab"
        :is-company-tab="isCompanyTab"
        :is-group-tab="isGroupTab"
        :user-role="userRole"
        :is-single-acc="isSingleAcc"
        :is-lavvira-tab="isLavviraTab"
        :actual-tab="actualTab"
      />
      <v-card
        flat
        :style="`height: ${vuetifyHeight - 225 + 'px'}; overflow-y: auto; overflow-x: hidden;`"
      >
        <v-row
          v-if="selection === 'cards'"
          class="mt-4 ml-9"
          justify="start"
        >
          <div
            v-for="(template, index) in filteredTemplatesByCategory"
            :key="index"
            class="mx-3 mb-3 mt-0"
          >
            <TemplateCard
              :template="template"
              :company="company"
              :group="group"
              :is-my-tab="isMyTab"
              :is-company-tab="isCompanyTab"
              :is-group-tab="isGroupTab"
              :user-role="userRole"
              :is-single-acc="isSingleAcc"
              :is-lavvira-tab="isLavviraTab"
              :is-sceleton="isSceleton"
              :actual-tab="actualTab"
              :template-card-index="index"
              @lavvira-template-preview="openPreviewDialog"
            />
          </div>
        </v-row>
      </v-card>
      <LavviraTemplatePreview
        v-if="previewTemplateData"
        ref="LavviraTemplatePreview"
        :template-data="previewTemplateData"
      />
    </v-container>
    <v-container
      v-else
      :style="`height: ${vuetifyHeight - 200 + 'px'};`"
    >
      <div
        class="center"
      >
        <v-icon
          color="primary"
          class="mb-1"
        >
          {{ templateIcon }}
        </v-icon>
        <span class="ml-2 mb-n1">{{ $t('templates|no_templates_created') }}</span>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import { mdiTextBoxOutline } from '@mdi/js'
import moment from 'moment'
import TemplatesTable from './TemplatesTable.vue'
import TemplatesOverviewBar from './TemplatesOverviewBar.vue'
import TemplateCard from './TemplateCard.vue'
import LavviraTemplatePreview from '@/components/dialogs/LavviraTemplatePreview.vue'
const createdAtFormat = date => moment(date).format('DD/MM/YYYY')

export default {
  components: { TemplatesTable, TemplatesOverviewBar, TemplateCard, LavviraTemplatePreview },
  props: {
    group: {
      type: Object,
      default: null
    },
    company: {
      type: Object,
      default: null
    },
    isCompanyTab: {
      type: Boolean,
      default: false
    },
    isGroupTab: {
      type: Boolean,
      default: false
    },
    isLavviraTab: {
      type: Boolean,
      default: false
    },
    isMyTab: {
      type: Boolean,
      default: false
    },
    isSingleAcc: {
      type: Boolean,
      default: false
    },
    userRole: {
      type: String,
      default: undefined
    },
    actualTab: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      selection: localStorage.getItem('preferedViewTemplates') || 'cards',
      templateSearch: '',
      selectedByCategorie: '',
      isSceleton: false,
      previewTemplateData: null,
      templateIcon: mdiTextBoxOutline
    }
  },
  computed: {
    ...mapState({
      templates: state => state.templates.templates,
      companyTemplates: state => state.companyTemplates.companyTemplates,
      groupTemplates: state => state.groupTemplates.groupTemplates,
      lavviraTemplates: state => state.templates.lavviraTemplates,
      account: state => state.account.account
    }),
    vuetifyHeight () {
      return this.$vuetify.breakpoint.height
    },
    myTemplates () {
      let myActualTemplates = []
      if (this.isSingleAcc && !this.isLavviraTab) {
        if (this.templates && this.templates.length) {
          myActualTemplates = this.templates
        }
      } else if (this.isSingleAcc && this.isLavviraTab) {
        myActualTemplates = []
      }
      if (!this.isCompanyTab && !this.isGroupTab && !this.isLavviraTab && !this.isSingleAcc && this.userRole !== 'regular') {
        if (this.templates && this.templates.length) {
          myActualTemplates = this.templates
        }
      }
      if (this.isCompanyTab) {
        myActualTemplates = this.companyTemplates
      }
      if (this.isGroupTab && this.group) {
        for (let i = 0; i < this.groupTemplates.length; i++) {
          const groupTemp = this.groupTemplates[i]
          groupTemp.sharedWith.groups.forEach(group => {
            if (this.group._id === group._id) {
              myActualTemplates.push(groupTemp)
            }
          })
        }
      }
      if (this.isLavviraTab) {
        myActualTemplates = this.lavviraTemplates
      }
      return myActualTemplates
    },
    _templates () {
      return this.myTemplates.map((t) => {
        const data = {
          ...t,
          updatedAt: createdAtFormat(t.updatedAt),
          createdAt: createdAtFormat(t.createdAt)
        }
        return data
      })
    },
    filteredTemplatesByCategory () {
      if (this.selectedByCategorie || (this.templateSearch && this.templateSearch.length)) {
        return this._templates.filter((t) => {
          return t.category.toLowerCase().includes(this.selectedByCategorie.toLowerCase()) && t.name.toLowerCase().includes(this.templateSearch.toLowerCase())
        })
      } else if (this.selectedByCategorie === '' || (this.templateSearch && this.templateSearch.length)) {
        return this._templates.filter((t) => {
          return t.name.toLowerCase().includes(this.templateSearch.toLowerCase())
        })
      } else if (this.selectedByCategorie === 'none' || (this.templateSearch && this.templateSearch.length)) {
        return this._templates.filter((t) => {
          return t.name.toLowerCase().includes(this.templateSearch.toLowerCase())
        })
      }
      return this._templates
    }
  },
  async created () {
    EventBus.$on('template-category-is-selected', this.onCategoryIsSelected)
    EventBus.$on('load-sceleton-card', this.onSceletonCardEvent)
    EventBus.$on('stop-loading-sceleton-card', this.onStopLoadingSceletonCard)
  },
  beforeDestroy () {
    EventBus.$off('template-category-is-selected', this.onCategoryIsSelected)
    EventBus.$off('load-sceleton-card', this.onSceletonCardEvent)
    EventBus.$off('stop-loading-sceleton-card', this.onStopLoadingSceletonCard)
  },
  methods: {
    openPreviewDialog (template) {
      this.previewTemplateData = template.templateData
      setTimeout(() => { this.$refs.LavviraTemplatePreview.lavviraTemplatePreviewDialog = true }, 300)
    },
    viewIsSelected (value) {
      this.selection = value
    },
    onCategoryIsSelected (selectedItem) {
      this.selectedByCategorie = selectedItem
    },
    onSceletonCardEvent (incommingTab) {
      if (incommingTab && incommingTab.company) {
        this.isSceleton = true
        this.companyTemplates.push({
          name: 'loading',
          isSceleton: this.isSceleton
        })
        // setTimeout(() => { alert('not processed') }, 120000)
      } else if (incommingTab && incommingTab.group) {
        this.isSceleton = true
        this.groupTemplates.push({
          name: 'loading',
          isSceleton: this.isSceleton
        })
      } else if (incommingTab && incommingTab.isMyTab) {
        this.isSceleton = true
        this.templates.push({
          name: 'loading',
          isSceleton: this.isSceleton
        })
      }
    },
    onStopLoadingSceletonCard (incommingTab) {
      if (incommingTab && incommingTab.company) {
        this.isSceleton = false
        const index = this.companyTemplates.findIndex(t => t.name === 'loading')
        this.companyTemplates.splice(index, 1)
      } else if (incommingTab && incommingTab.group) {
        this.isSceleton = false
        const index = this.groupTemplates.findIndex(t => t.name === 'loading')
        this.groupTemplates.splice(index, 1)
      } else if (incommingTab && incommingTab.isMyTab) {
        this.isSceleton = false
        const index = this.templates.findIndex(t => t.name === 'loading')
        this.templates.splice(index, 1)
      }
    }
  }
}

</script>

<style scoped>
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
