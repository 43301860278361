<template>
  <v-toolbar
    flat
    rounded-0
    class="mt-0"
    color="lightBackground"
  >
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      bottom
      color="deep-purple accent-4"
    />
    <div
      class="d-flex row justify-start align-center px-8"
    >
      <v-text-field
        :solo-inverted="soloInverted"
        :solo="solo"
        :value="value"
        prepend-inner-icon="mdi-magnify"
        :label="$t('actions|search_templates')"
        hide-details
        dense
        rounded
        class="shrink"
        @focus="soloInverted = true; solo = false"
        @blur="soloInverted = false; solo = true"
        @input="event => this.$emit('input', event)"
      />
      <v-btn
        v-if="selection === 'table'"
        color="primary"
        text
        dark
        small
        class="ml-2 pl-2 pr-2"
        @click="viewIsSelected('cards')"
      >
        <v-icon
          small
          class="mr-1"
        >
          mdi-cards-variant
        </v-icon>
        {{ $t('common|cards') }}
      </v-btn>
      <v-btn
        v-if="selection === 'cards'"
        color="primary"
        text
        dark
        small
        class="ml-2 pl-2 pr-2"
        @click="viewIsSelected('table')"
      >
        <v-icon
          small
          class="mr-1"
        >
          mdi-table-large
        </v-icon>
        {{ $t('common|table') }}
      </v-btn>
      <v-spacer />
      <v-btn
        v-if="!isLavviraTab && account.accountType !== 'god' && windowWidth >= 1264"
        color="primary"
        rounded
        small
        :disabled="!activeButton(account, company, group, actualTab)"
        class="my-2"
        @click="createTemplate"
      >
        <v-icon
          left
        >
          mdi-file-plus-outline
        </v-icon>
        {{ $t('actions|add_new_template') }}
      </v-btn>
      <v-btn
        v-if="!isLavviraTab && account.accountType !== 'god' && windowWidth < 1264"
        color="primary"
        fab
        :small="windowWidth > 837"
        :x-small="windowWidth <= 837"
        :disabled="!activeButton(account)"
        class="my-2"
        @click="createTemplate"
      >
        <v-icon
          dark
        >
          mdi-plus
        </v-icon>
      </v-btn>
      <v-btn
        v-if="isLavviraTab && account.accountType === 'god'"
        color="primary"
        rounded
        small
        class="mb-2"
        @click="createTemplate"
      >
        <v-icon
          left
        >
          mdi-file-plus-outline
        </v-icon>
        {{ $t('actions|add_new_template') }}
      </v-btn>
    </div>
  </v-toolbar>
</template>

<script>
import { EventBus } from '@/utils/EventBus'
import { mapState } from 'vuex'
import { userHasAccess } from '@/utils/utils'

export default {
  props: {
    value: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    group: {
      type: Object,
      default: null
    },
    company: {
      type: Object,
      default: null
    },
    isMyTab: {
      type: Boolean,
      default: false
    },
    isCompanyTab: {
      type: Boolean,
      default: false
    },
    isGroupTab: {
      type: Boolean,
      default: false
    },
    isLavviraTab: {
      type: Boolean,
      default: false
    },
    isSingleAcc: {
      type: Boolean,
      default: false
    },
    userRole: {
      type: String,
      default: undefined
    },
    actualTab: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      selection: localStorage.getItem('preferedViewTemplates') || 'cards',
      soloInverted: false,
      solo: true,
      windowWidth: window.innerWidth
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account
    })
  },
  mounted () {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  methods: {
    createTemplate () {
      if (this.isCompanyTab) {
        EventBus.$emit('dialog-add-template', { company: this.company, isCompanyTab: true })
      } else if (this.isGroupTab) {
        EventBus.$emit('dialog-add-template', { group: this.group, isGroupTab: true })
      } else if (this.isLavviraTab) {
        EventBus.$emit('dialog-add-template', { isLavviraTab: true })
      } else if (!this.isCompanyTab && !this.isGroupTab && !this.isLavviraTab && this.isMyTab) {
        EventBus.$emit('dialog-add-template', { accountId: this.account._id, isMyTab: true })
      }
    },
    viewIsSelected (value) {
      this.selection = value
      this.$emit('selection', value)
      localStorage.setItem('preferedViewTemplates', value)
    },
    activeButton (account, company, group, tab) {
      return userHasAccess(account, company, group, tab)
    }
  }
}
</script>
